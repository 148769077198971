<template>
    <v-slider
        :max="max"
        :min="min"
        :value="setValue"
        class="customSlider"
        color="primary"
        height="10"
        thumb-color="primary"
        thumb-label="always"
        track-color="#e5e5e5"
        v-on:input="updateValue($event)"
    >
        <template v-slot:prepend>
            {{ min }}
        </template>

        <template v-slot:append>
            {{ max }}{{ symbol }}
        </template>
    </v-slider>
</template>

<script>

export default {
    name: 'CustomSlider',
    props: {
        min: {
            default: 0
        },
        max: {
            default: 20
        },
        setValue: {
            default: 0
        },
        value: {
            type: Number
        },
        symbol: {
            type: String,
        }
    },
    data: () => ({}),
    methods: {
        updateValue: function (value) {
            this.$emit('input', value)
        }
    }
};
</script>

<style scoped>
.customSlider .v-slider__thumb-label {
    transform: translateY(100%) translateY(-12px) translateX(-50%) rotate(0) !important;
    border-radius: 20% !important;
    background-color: white !important;
    color: black;
    font-weight: bold;
    height: 27px !important;
    width: 20px !important;
    box-shadow: 0px 0px 3px black
}

.customSlider .v-slider__thumb-label > * {
    transform: rotate(0) !important;
}

.customSlider .v-slider__track-container {
    height: 8px !important;
}

.customSlider .v-input__slot {
    margin-top: 7px;
}
</style>

