<template>
    <div class="audits">
        <div class="py-6 title audits-title">
            <span>{{ $t('audits.title') }}</span>
        </div>
        <v-card class="card py-4 px-8" elevation="4">
            <div class="table-wrapper">
                <div class="table-header">
                    <span>{{ $t('audits.tableHeader1') }}</span>
                    <span>{{ $t('audits.tableHeader2') }}</span>
                    <span>{{ $t('audits.tableHeader3') }}</span>

                </div>
                <div class="horizontal-divider"></div>
                <div class="table-body">
                    <div class="table-elmt-wrapper">
                        <div class="table-elmt-divider mt-2">
                            <span class="elmt-btn">{{ $t('audits.editor') }}</span>
                            <span class="elmt-btn">{{ $t('audits.type1', {max: oldValue.suspectBasketNbProduct}) }}</span>
                            <span class="elmt-btn">{{ getDateLessDays(1) }} - 12:06:12</span>
                        </div>
                        <div class="table-elmt-divider mt-2">
                            <span class="elmt-btn">{{ $t('audits.editor') }}</span>
                            <span class="elmt-btn">{{ $t('audits.type2', {max: oldValue.suspectBasketNbProductDeleted}) }}</span>
                            <span class="elmt-btn">{{ getDateLessDays(3) }} - 14:58:36</span>
                        </div>
                        <div class="table-elmt-divider mt-2">
                            <span class="elmt-btn">{{ $t('audits.editor') }}</span>
                            <span class="elmt-btn">{{ $t('audits.type3', {max: oldValue.suspectBasketTimeBetweenOrderAndPayment}) }}</span>
                            <span class="elmt-btn">{{ getDateLessDays(7) }} - 17:46:25</span>
                        </div>
                        <div class="table-elmt mt-2">
                            <span class="elmt-btn">{{ $t('audits.editor') }}</span>
                            <span class="elmt-btn">{{ $t('audits.type4', {max: oldValue.suspectBasketExpansiveProduct}) }}</span>
                            <span class="elmt-btn">{{ getDateLessDays(10) }} - 18:52:14</span>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>

        <div class="table-footer my-8 float-end">
            <div class="page-selector">
                <v-icon ref="previous-orders" disabled>mdi-arrow-left</v-icon>
                <span ref="page" class="mx-2">1</span>
                <v-icon ref="next-orders">mdi-arrow-right</v-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Audits",
    props: ['suspectBasketNbProduct', 'suspectBasketNbProductDeleted', 'suspectBasketTimeBetweenOrderAndPayment', 'suspectBasketExpansiveProduct'],
    data: () => ({
        oldValue: {
            suspectBasketNbProduct: null,
            suspectBasketNbProductDeleted: null,
            suspectBasketTimeBetweenOrderAndPayment: null,
            suspectBasketExpansiveProduct: null
        }
    }),
    created() {
    },
    methods: {
        updateValue() {
            if (this.oldValue.suspectBasketNbProduct == null) {
                this.oldValue.suspectBasketNbProduct = this.suspectBasketNbProduct
                this.oldValue.suspectBasketNbProductDeleted = this.suspectBasketNbProductDeleted
                this.oldValue.suspectBasketTimeBetweenOrderAndPayment = this.suspectBasketTimeBetweenOrderAndPayment
                this.oldValue.suspectBasketExpansiveProduct = this.suspectBasketExpansiveProduct
            }
        }
    },
    watch: {
        $props: {
            handler() {
                this.updateValue();
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.audits-title {
    font-weight: bold;
    color: black;
}

.audits .card {
    border-radius: 10px !important;
}

.audits .table-wrapper {
    margin: 0 !important;
}
</style>
