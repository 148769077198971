<template>
    <div>
        <h3 class="py-5">{{$t('suspectBasketConfiguration.title')}}</h3>
        <label >{{$t('suspectBasketConfiguration.nbProduct')}}</label>
        <CustomSlider class="py-3"  :min="0" :max="20" :setValue="suspectBasketNbProduct" v-model="suspectBasketNbProduct"/>
        <label >{{$t('suspectBasketConfiguration.nbProductDeleted')}}</label>
        <CustomSlider class="py-3" :min="0" :max="20" :setValue="suspectBasketNbProductDeleted" v-model="suspectBasketNbProductDeleted" />
        <label>{{$t('suspectBasketConfiguration.timeBetweenOrderAndPayment')}}</label>
        <CustomSlider class="py-3"  :min="0"  :max="60" :setValue="suspectBasketTimeBetweenOrderAndPayment" v-model="suspectBasketTimeBetweenOrderAndPayment" />
        <label>{{$t('suspectBasketConfiguration.expansiveProduct')}}</label>
        <CustomSlider class="py-3"  :min="0" :max="50" :setValue="suspectBasketExpansiveProduct" symbol="€00" v-model="suspectBasketExpansiveProduct"/>
        <div class="text-center">
            <v-btn rounded outlined color="primary" class="ma-2">{{$t('reinitBtn')}}</v-btn>
            <v-btn rounded color="primary" @click="submit()" class="ma-2">{{$t('saveBtn')}}</v-btn>
        </div>

        <div class="horizontal-divider"></div>

        <Audits :suspectBasketNbProduct="suspectBasketNbProduct"
                :suspectBasketNbProductDeleted="suspectBasketNbProductDeleted"
                :suspectBasketTimeBetweenOrderAndPayment="suspectBasketTimeBetweenOrderAndPayment"
                :suspectBasketExpansiveProduct="suspectBasketExpansiveProduct"
        ></Audits>

    </div>
</template>

<script>
import CustomSlider from "@/components/IEC/CustomSlider.vue"
import {config} from '@/assets/js/Utils.js';
import Audits from "@/components/IEC/Audits";

var axios = require('axios');

export default {
    name: 'SuspectBasketConfiguration',
    components: {
        Audits,
        CustomSlider
    },
    data: () => ({
        keys:["suspectBasketNbProduct","suspectBasketNbProductDeleted","suspectBasketTimeBetweenOrderAndPayment","suspectBasketExpansiveProduct"],
        suspectBasketNbProduct:0,
        suspectBasketNbProductDeleted:0,
        suspectBasketTimeBetweenOrderAndPayment:0,
        suspectBasketExpansiveProduct:0
    }),
    created() {
        this.updatePageInfo(this.$route.meta)
    },
    mounted() {
        let stringKeys = "{\"configurationsKeys\": ["
        this.keys.forEach(k => {
            stringKeys += "{\"configurationKey\":\""+k+"\"},"
        })
        stringKeys = stringKeys.substr(0,stringKeys.length -1)
        stringKeys += "]}"
        axios.get(config.WS_URL + "/configuration/get/keys?configurationKeys="+new URLSearchParams(stringKeys).toString())
            .then(response => {
                response.data.forEach(element => {
                    this[element.configurationKey] = parseInt(element.configurationValue)
                })
            })
    },
    methods:{
        submit:function(){
            let stringKeys = "{\"configurations\": ["
            this.keys.forEach(k => {
                stringKeys += "{\"configurationKey\":\""+k+"\",\"configurationValue\":\""+this[k]+"\"},"
            });
            stringKeys = stringKeys.substr(0,stringKeys.length -1)
            stringKeys += "]}"
            const params = new URLSearchParams();
            params.append("configurations",stringKeys)
            axios.post(config.WS_URL + "/configuration/add", params)
                .then(response => {
                    if (response.status === 200) {
                        this.$root.$emit('showAlert', {"content":  this.$t('suspectBasketConfiguration.saveSuccessfull'), "color": "success", "timeout": "5", "closable": false});
                    }else{
                        this.$root.$emit('showAlert', {"content":  this.$t('suspectBasketConfiguration.saveError'), "color": "error", "timeout": "5", "closable": false});
                    }
                });
        }
    },
};
</script>
<style scoped>
</style>
